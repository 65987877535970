// bootstrap dependencies
import $ from 'jquery/dist/jquery'
// import 'popper.js/dist/popper'

// bootstrap
// import 'bootstrap/js/src/alert'
// import 'bootstrap/js/src/button'
// import 'bootstrap/js/src/carousel'
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/dropdown'
// import 'bootstrap/js/src/index'
// import 'bootstrap/js/src/modal'
// import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/scrollspy'
// import 'bootstrap/js/src/tab'
// import 'bootstrap/js/src/tooltip'
// import 'bootstrap/js/src/util'

// custom styles
import '../scss/style.scss';

$(document).ready(function() {
  // open and close mobile menu
  $('#mobile-menu-button').click(function() {
    $(this).toggleClass('open');
    $('#mobile-menu-container').toggleClass('open');
  });

});
